.output-container {
    border: 1px solid #555;
    background-color: white;
    width:25%;
    border-radius: 5px;
    padding: 10px;
}

.output-title {
    font-size: large;
    font-weight: 700;
    text-align: center;
}

u{
    text-decoration: none;
    border-bottom: 8px solid aquamarine; 
    display: inline-block;
}

p{
    /* max-width: 350px; */
    font-size:small;
}