.input-container {
    border: 1px solid #555;
    background-color: white;
    border-radius: 5px;
    padding: 10px;
}

.input-title {
    font-size: large;
    font-weight: 700;
    text-align: center;
}

u {
    text-decoration: none;
    border-bottom: 8px solid aquamarine;
    display: inline-block;
}

input:disabled, select:disabled {
    background-color: #a0a0a0;
    color: black;
}
